import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { contactHolder } from '../styles/pages.module.scss';
import Seo from '../components/utilities/Seo';
import Keychange from '../components/components/Keychange';

export default function ContactPage() {
  return (
    <article className={contactHolder}>
      <Seo title="Eliza Shaddad" description="The official website" />
      <ul className="deets">
        <li>
          Mgmt - <a href="mailto:mgmt.elizashaddad@gmail.com">mgmt.elizashaddad@gmail.com</a> <FontAwesomeIcon icon={faEnvelope} />{' '}
        </li>
        <li>
          Live - <a href="mailto:Marlon@ATC-Live.com">marlon@ATC-Live.com</a> <FontAwesomeIcon icon={faEnvelope} />
        </li>
      </ul>

      <Keychange />
    </article>
  );
}
