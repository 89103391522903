import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { keychangeHolder } from './Keychange.module.scss';

export default function Keychange() {
  return (
    <div className={keychangeHolder}>
      <p>
        Eliza Shaddad is powered by{' '}
        <a href="https://www.keychange.eu/" target="_blank" rel="noreferrer">
          Keychange
        </a>
        - an international campaign led by Reeperbahn Festival, PRS Foundation and Musikcentrum Öst, supported by the Creative Europe Programme of the
        European Union, in partnership with Tallinn Music Week, Iceland Airwaves, BIME, Oslo World, Linecheck, Ireland Music Week, SACEM, Liverpool
        Sound City, Way Out West, Different Sound MAMA Mek and Breakout West.
      </p>
      <a href="https://www.keychange.eu/" target="_blank" rel="noreferrer">
        <StaticImage src="../../images/keychangelogos.png" alt="Eliza Shaddad is powered by Keychange" />
      </a>
    </div>
  );
}
